<template>
  <div class="container">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :text="'Resetowanie hasła'" :short="true" />
        <TextDescription
          :text="'Wyślemy Ci wiadomość z linkiem, za pomocą którego ustawisz nowe hasło'"
        />
        <InputTextEmailValidation
          v-model="loginEmail.value"
          @update:isValidEmail="setIsValidEmail"
        />
        <ButtonPrimaryDone
          @click="handleDone"
          :isDisabled="!loginEmail.isValid"
        />
      </div>
    </LayoutViewWrapperNarrow>
    <NavMobile v-if="isMobile" />
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import usePasswordReset from "@/composables/usePasswordReset";
import { ROUTE_PASSWORD_RESET_RESEND_LINK_NAME } from "@/router/constants";

import useIsMobile from "@/composables/useIsMobile";

import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import NavMobile from "@/components/NavMobile.vue";
import InputTextEmailValidation from "@/components/UI/InputTextEmailValidation.vue";
import ButtonPrimaryDone from "@/components/UI/ButtonPrimaryDone.vue";

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    NavMobile,
    InputTextEmailValidation,
    ButtonPrimaryDone,
  },

  setup() {
    const { isMobile } = useIsMobile();
    const { sendPasswordResetLink } = usePasswordReset();
    const router = useRouter();

    const loginEmail = reactive({
      value: null,
      isValid: false,
    });

    const routePasswordResetLink = computed(() => {
      return {
        name: ROUTE_PASSWORD_RESET_RESEND_LINK_NAME,
        query: {
          email: loginEmail.value,
        },
      };
    });

    const setIsValidEmail = (newIsValid) => {
      loginEmail.isValid = newIsValid;
    };

    const handleDone = async () => {
      try {
        if (loginEmail.isValid === false || loginEmail.value === null) {
          throw new Error("Please enter a valid email address");
        }
        await sendPasswordResetLink(loginEmail.value);
        await router.push(routePasswordResetLink.value);
      } catch (error) {
        alert(error.message);
      }
    };

    return {
      loginEmail,
      handleDone,
      isMobile,
      setIsValidEmail,
    };
  },
};
</script>

<style scoped>
.container {
  padding-bottom: var(--header-default--height);
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .container {
    padding-bottom: 0px;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 60px;
  }
}
</style>
