<template>
  <InputTextEmail
    v-model="email"
    :pattern="'[^@\\s]+@[^@\\s]+\\.[^@\\s]{2,4}'"
    @focus="isErrorEmail = false"
    @blur="validateEmail"
    @input="$emit('update:isValidEmail', isValidEmail)"
    :error="isErrorEmail"
    :success="isValidEmail"
    :required="true"
  />
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import { watch } from "vue";

import InputTextEmail from "@/components/UI/InputTextEmail.vue";

export default {
  components: { InputTextEmail },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidEmail"],

  setup(props, { emit }) {
    const email = ref(props.modelValue);

    // Email valiadtion
    const isErrorEmail = ref(false);
    const validateEmail = () => {
      isErrorEmail.value = !isValidEmail.value;
    };
    const isValidEmail = computed(() => {
      if (email.value == null) {
        return false;
      }

      let regex = /[^@\s]+@[^@\s]+\.[^@\s]{2,4}/i;

      return regex.test(email.value);
    });

    watch(email, (newEmail) => {
      emit("update:modelValue", newEmail);
    });
    watch(
      () => props.modelValue,
      (newEmail) => {
        email.value = newEmail;
      }
    );

    watch(
      isValidEmail,
      (newIsValidEmail) => {
        emit("update:isValidEmail", newIsValidEmail);
      },
      { immediate: true }
    );

    return {
      email,
      isErrorEmail,
      validateEmail,
      isValidEmail,
    };
  },
};
</script>
