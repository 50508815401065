<template>
  <InputText
    :type="'text'"
    :name="'email'"
    :autocomplete="'off'"
    :spellcheck="false"
    :label="'Email'"
    :placeholder="'name@example.com'"
  />
</template>

<script>
import InputText from "@/components/UI/Base/InputText.vue";

export default {
  components: { InputText },
};
</script>
